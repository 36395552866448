import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../components/layout'
import SEO from '../components/seo'
import WifiForm from '../components/wifiForm'

const WifiPage = ({ data: { icon } }) => {
  const { formatMessage } = useIntl()

  return (
    <Layout isLight icon={icon}>
      <SEO title={ formatMessage({ id: 'accedi_wifi' }) } />
      <WifiForm />
    </Layout>
  )
}

export const query = graphql`
query WifiPageImage {
  icon: file(relativePath: { eq: "casette.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default WifiPage
