import React, { useState } from 'react'
import classNames from 'classnames'
import queryString from 'query-string'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { useCookies } from 'react-cookie'

import LrButton from './lrButton'

const WifiForm = () => {
  const { formatMessage } = useIntl()

  const [email, setEmail] = useState('')
  const [privacy, setPrivacy] = useState(false)
  const [cookies] = useCookies()
  const { ga_srvr: url } = queryString.parse(cookies.captive_portal)
  const action = `http://${url}:880/cgi-bin/hotspot_login.cgi${cookies.captive_portal}`
  const submitForm = (event) => {
    event.preventDefault()
    // TODO: Invio della mail
    console.log(action)
    event.target.submit()
  }

  return (
    <div className="wifi-form">
      <h1 className="wifi-form__title text-4xl font-dancing text-center">
        <FormattedMessage id="accedi_rete_wifi" />
      </h1>
      <div className="wifi-form__main wf-main my-12">
        <h2 className="wf-main_title uppercase text-center text-2xl">
          <FormattedMessage id="inserisci_email" />
        </h2>
        <form action={ action } onSubmit={ submitForm } method="POST" className="wf-main__form wf-form px-8 mt-6">
          <input
            type="email"
            name="email"
            id="email"
            placeholder={ formatMessage({ id: 'email_placeholder' }) }
            required
            value={ email }
            onChange={ (event) => setEmail(event.target.value) }
            className="wf-form__email block w-full mx-2 rounded-xl bg-white px-6 py-4"
          />
          <label
            htmlFor="privacy"
            className="wf-form__privacy form-privacy flex my-8 items-center justify-center"
          >
            <input
              type="checkbox"
              name="privacy"
              id="privacy"
              value="privacy-accepted"
              aria-label={ formatMessage({ id: 'accetta_privacy' }) }
              checked={ privacy }
              required
              onChange={ () => privacy ? setPrivacy(false) : setPrivacy(true) }
              className="form-privacy__input opacity-0 w-0 h-0"
            />
            <div
              className={ classNames('form-privacy__box rounded-full w-4 h-4 mr-2', {
                'bg-white': !privacy,
                'bg-orange-900': privacy
              }) }
              aria-hidden
            />
            <div className="form-privacy__text">
              <span className="mr-1">
                <FormattedMessage id="accetta" />
              </span>
              <a
                href="https://www.iubenda.com/privacy-policy/50287714"
                target="_blank"
                rel="noreferrer noopener nofollow"
                className="text-orange-900 hover:text-orange-800 transition duration-150"
              >
                <FormattedMessage id="informativa_sulla_privacy" />
              </a>
            </div>
          </label>
          <div className="flex items-center justify-center mt-12 mb-8">
            <LrButton disabled={ !email || !privacy } type="submit">
              <FormattedMessage id="invia" />
            </LrButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default WifiForm
